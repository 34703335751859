@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
html {
  scroll-behavior: smooth;
}
body{font-family: 'Roboto', sans-serif;}
.section-title {
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: solid 4px white;
  box-shadow: 2px 2px 6px #7e7e7e
}
.unit-container{
  background: linear-gradient(
    to bottom,
    transparent,
    transparent 30%,
    #000066 0%,
    #000066 80%,
    transparent 20%,
    transparent
    );
}
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 962px) { 
  .unit-container{
    background: linear-gradient(
      to bottom,
      white 0%,
      white 30%,
      #000066 30%,
      #000066 75%,
      white 75%
      );
  }
 }
 ol { margin-left: 40px;}
ol > li {
  list-style-type: decimal;
}
 h1 { font-size: 3rem; margin-bottom: 30px;}
 p { margin-bottom: 8px; line-height: 1.8rem;}